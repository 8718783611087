*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    line-height: $line-height-default;
    font-size: $font-size-default;
    font-family: $font-family-default;
    background: $color-white;
    color: $color-text;
}

body {
    margin: 0;
}

img,
iframe {
    max-width: 100%;
}

a {
    text-decoration: none;
    color: $color-brand;
    &:hover &:focus &:active {
        color: $color-brand;
        text-decoration: underline;
    }
}

b {
    font-weight: inherit;
    color: $color-brand;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 $margin-default;
    line-height: $line-height-headings;
    font-size: 20px;
    font-weight: 700;
    font-family: $font-family-headings;
    text-transform: uppercase;
}

@media (min-width: 768px) {
    h1 {
        font-size: px;
    }
    h2 {
        font-size: 32px;
    }
    h3 {
        font-size: 28px;
    }
    h4 {
        font-size: 24px;
    }
    h5 {
        font-size: 20px;
    }
    h6 {
        font-size: 16px;
    }
}

p,
ul,
ol,
table {
    margin: 0 0 $margin-default;
}

main {
    @include u-z-index;
    position: relative;
}

.sr-only {
    @include u-sr-only;
}

.container-fluid {
    width: $width-container;
    max-width: 100%;
    padding: 0 $padding-gutter;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .l-row {
        display: -webkit-flex;
        display: flex;
        margin: 0 $padding-gutter * -1;
    }
    .l-col {
        flex: 1 1;
        padding: 0 $padding-gutter;
    }
}

.w-btn {
    display: inline-block;
    width: auto;
    padding: 11px 22px;
    border: 1px solid #333;
    border-radius: 3rem;
    font-size: 12px;
    text-align: center;
    line-height: 14px;
    letter-spacing: 0.12em;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.5s;
    background: #333;
    color: #fff;
}

.btn-primary {
    background: #333;
    color: $color-white;
    &:hover,
    &:focus,
    &:active {
        background-color: #000;
        color: $color-white;
    }
}