.w-footer 
{ 
	padding: 25px 0;
	background: $color-grey-background;
}
.w-footer .container-fluid 
{
	@include u-clearfix;
}
.w-footer-logo 
{ 

}
.w-footer-content 
{ 
	padding: 25px 0 0;
}
.w-footer-content h2 
{
	padding: 0;
	margin: 0;
	
	font-size: 28px;
}
.w-footer-copyright 
{ 

}
@media (min-width: 768px)
{
	.w-footer 
	{ 
		padding: 5.5% 0;
	}
	.w-footer-logo 
	{ 
		float: left;
	}
	.w-footer-content 
	{ 
		float: right;
		text-align: right;
	}
	.w-footer-content h2 
	{
		font-size: 35px;
	}
}
@media (max-width: 767px)
{
	.w-footer
	{
		text-align: center;
	}
	.w-footer-content 
	{
		padding-top: 15px;
	}
	.w-footer-content h2 
	{
		margin-bottom: 15px;
		text-align: center;
	}
	.w-footer-content h2 b
	{
		display: block;
	}
	.w-footer-copyright
	{
		font-size: 14px;
	}
}
@media (max-width: 449px)
{
	.w-footer-copyright
	{
		font-size: 11px;
	}
}