@mixin u-z-index
{
    $z-list:
		'.w-banner .cycle-slideshow'
		'.w-banner .cycle-pager'
		'main'
		'.w-header'
    ;
    $me: "#{&}";
    $z-val: index($z-list, $me);
    z-index: $z-val * 10 + 100;
}
@mixin u-sr-only 
{
	position: absolute;
	left: -1000em;
}
@mixin u-clearfix 
{
	&::after 
	{
		clear: both;
		display: table;
		content: "";
	}
}
@mixin w-bullets($size)
{
	position: relative;
	padding-left: $size * 2;

	&::before
	{
		position: absolute;
		top: 50%;
		left: 0;

		content: "";
		width: $size;
		height: $size;

		background: url("/img/arrow-right-brand.svg") no-repeat 50% 50%;
		background-size: contain;

		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}