.w-locations {
    padding: 25px 0;
}

.w-location-box {
    box-shadow: 0 0 15px 15px rgba(0, 0, 0, 0.04);
}

.w-location-header {
    @include u-clearfix;
    position: relative;
    padding: 25px;
    background: $color-white;
    color: $color-grey-text;
}

.w-location-header h2 {
    margin-bottom: 7px;
    color: $color-text;
}

.w-location-header b {
    color: $color-text;
}

.w-location-header-menu ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.w-location-header-menu li {
    display: block;
    padding: 0;
    margin: 0;
}

.w-location-header-menu a {
    @include w-bullets(12px);
    display: block;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 10px;
    font-size: 14px;
    text-transform: uppercase;
    color: $color-text;
}

.w-location-map {
    position: relative;
    height: 250px;
}

@media (max-width: $width-container - 1px) {
    .w-location-map.w-touched::before {
        display: none;
    }
    .w-location-map::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: rgba(0, 0, 0, 0.2);
    }
}

.w-location-map iframe {
    display: block;
    width: 100%;
    height: 100%;
}

.w-location-buttons {
    margin: 20px 0;
}

.w-location-buttons {
    display: block;
}

.w-location-buttons .w-btn {
    display: block;
    margin: 0;
}

.w-location-buttons .w-btn+.w-btn {
    margin: 20px 0 0;
}

@media (min-width: 768px) {
    .w-location-header {
        padding: 30px;
        font-size: 20px;
    }
    .w-location-header-menu li {
        float: left;
        width: 50%;
    }
    .w-location-header p {
        margin: 0 0 2em;
    }
}

@media (min-width: 1024px) {
    .w-locations {
        padding: 5.5% 0;
    }
    .w-banner+.w-locations,
    .w-banner+main>.w-locations:first-child {
        padding-top: 0;
        margin-top: -40px;
    }
    .w-location-header {
        padding: 50px;
    }
    .w-location-header h2 {
        padding-right: 50px;
    }
    .w-location-header::before {
        position: absolute;
        top: 14px;
        right: 14px;
        content: "";
        width: 60px;
        height: 50px;
        background: url("../img/logo.png") no-repeat 50% 50%;
        background-size: contain;
    }
    .w-location-buttons {
        -webkit-display: flex;
        display: flex;
    }
    .w-location-buttons .w-btn {
        -webkit-flex: 1 1 auto;
        flex: 1 1 auto;
        margin: 0 10px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .w-location-buttons .w-btn+.w-btn {
        margin-top: 0;
    }
}