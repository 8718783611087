//new fonts
@font-face {
    font-family: 'ToyotaType-Light';
    src: url('/fonts/ToyotaType-Light.eot');
    src: url('/fonts/toyota-type/ToyotaType-Light.eot?#iefix') format('embedded-opentype'), url('/fonts/toyota-type/ToyotaType-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'ToyotaType';
    src: url('/fonts/toyota-type/ToyotaType-Regular.eot');
    src: url('/fonts/toyota-type/ToyotaType-Regular.eot?#iefix') format('embedded-opentype'), url('/fonts/toyota-type/ToyotaType-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ToyotaType-Semibold';
    src: url('/fonts/toyota-type/ToyotaType-Semibold.eot');
    src: url('/fonts/toyota-type/ToyotaType-Semibold.eot?#iefix') format('embedded-opentype'), url('/fonts/toyota-type/ToyotaType-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'ToyotaType-Bold';
    src: url('/fonts/toyota-type/ToyotaType-Bold.eot');
    src: url('/fonts/toyota-type/ToyotaType-Bold.eot?#iefix') format('embedded-opentype'), url('/fonts/toyota-type/ToyotaType-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

// @font-face {
//     font-family: 'Toyota Display';
//     src: url('/fonts/ToyotaDisplay-Bold.eot');
//     src: url('/fonts/ToyotaDisplay-Bold.eot?#iefix') format('embedded-opentype'),
//         url('/fonts/ToyotaDisplay-Bold.woff2') format('woff2'),
//         url('/fonts/ToyotaDisplay-Bold.woff') format('woff'),
//         url('/fonts/ToyotaDisplay-Bold.ttf') format('truetype'),
//         url('/fonts/ToyotaDisplay-Bold.svg#ToyotaDisplay-Bold') format('svg');
//     font-weight: bold;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Toyota Text';
//     src: url('/fonts/ToyotaText-BoldItalic.eot');
//     src: url('/fonts/ToyotaText-BoldItalic.eot?#iefix') format('embedded-opentype'),
//         url('/fonts/ToyotaText-BoldItalic.woff2') format('woff2'),
//         url('/fonts/ToyotaText-BoldItalic.woff') format('woff'),
//         url('/fonts/ToyotaText-BoldItalic.ttf') format('truetype'),
//         url('/fonts/ToyotaText-BoldItalic.svg#ToyotaText-BoldItalic') format('svg');
//     font-weight: bold;
//     font-style: italic;
// }
// @font-face {
//     font-family: 'Toyota Text';
//     src: url('/fonts/ToyotaText-Italic.eot');
//     src: url('/fonts/ToyotaText-Italic.eot?#iefix') format('embedded-opentype'),
//         url('/fonts/ToyotaText-Italic.woff2') format('woff2'),
//         url('/fonts/ToyotaText-Italic.woff') format('woff'),
//         url('/fonts/ToyotaText-Italic.ttf') format('truetype'),
//         url('/fonts/ToyotaText-Italic.svg#ToyotaText-Italic') format('svg');
//     font-weight: normal;
//     font-style: italic;
// }
// @font-face {
//     font-family: 'Toyota Display';
//     src: url('/fonts/ToyotaDisplay-Regular.eot');
//     src: url('/fonts/ToyotaDisplay-Regular.eot?#iefix') format('embedded-opentype'),
//         url('/fonts/ToyotaDisplay-Regular.woff2') format('woff2'),
//         url('/fonts/ToyotaDisplay-Regular.woff') format('woff'),
//         url('/fonts/ToyotaDisplay-Regular.ttf') format('truetype'),
//         url('/fonts/ToyotaDisplay-Regular.svg#ToyotaDisplay-Regular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Toyota Text';
//     src: url('/fonts/ToyotaText-Bold.eot');
//     src: url('/fonts/ToyotaText-Bold.eot?#iefix') format('embedded-opentype'),
//         url('/fonts/ToyotaText-Bold.woff2') format('woff2'),
//         url('/fonts/ToyotaText-Bold.woff') format('woff'),
//         url('/fonts/ToyotaText-Bold.ttf') format('truetype'),
//         url('/fonts/ToyotaText-Bold.svg#ToyotaText-Bold') format('svg');
//     font-weight: bold;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'Toyota Text';
//     src: url('/fonts/ToyotaText-Regular.eot');
//     src: url('/fonts/ToyotaText-Regular.eot?#iefix') format('embedded-opentype'),
//         url('/fonts/ToyotaText-Regular.woff2') format('woff2'),
//         url('/fonts/ToyotaText-Regular.woff') format('woff'),
//         url('/fonts/ToyotaText-Regular.ttf') format('truetype'),
//         url('/fonts/ToyotaText-Regular.svg#ToyotaText-Regular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }