.w-banner
{
	position: relative;
}
.w-banner .cycle-slide 
{
	width: 100%;
	background: no-repeat 50% 50%;
	background-size: cover;
}
.w-banner .cycle-slide img 
{
	display: block;
	width: 100%;
}
.w-banner .cycle-pager 
{
	@include u-z-index;

	position: absolute;
	right: 0;
	bottom: 10px;
	left: 0;
	
	text-align: center;
}
.w-banner .cycle-pager span 
{
	overflow: hidden;
	display: inline-block;
	
	width: 10px;
	height: 10px;
	border: solid 1px $color-white;
	margin: 0 5px;

	border-radius: 50%;
	text-indent: -2000em;
	transition: all 0.3s;
}
.w-banner .cycle-pager span.cycle-pager-active
{
	background: $color-white;
}
@media (min-width: 768px)
{
	.w-banner .cycle-pager 
	{
		bottom: 50px;
	}
}
@media (max-width: 767px)
{
	.w-banner .cycle-slide
	{
		height: 200px;
	}
	.w-banner .cycle-slide img 
	{
		display: none;
	}
	.w-banner .cycle-pager 
	{
		bottom:0;
	}
}
