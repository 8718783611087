.w-header {
    @include u-z-index;
}

.w-header .container-fluid {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
}

.w-header-logo {
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    padding: 20px;
    background: white;
}

.w-header-logo img {
    display: block;
}

.w-header-title {
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
}

.w-header-title h1,
.w-header-title h2 {
    padding: 0;
    margin: 0;
    font-size: 24px;
}

.w-header-title-location {
    position: relative;
    padding: 0 0 0 27px;
    line-height: 1;
    font-size: 14px;
    text-transform: uppercase;
}

.w-header-title-location {
    @include w-bullets(14px);
}

@media (min-width: 768px) {
    .w-header {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        color: $color-white;
    }
    .w-header-logo {
        padding: 40px 32px;
    }
    .w-header-title h1,
    .w-header-title h2 {
        font-size: 48px;
        color: $color-white;
    }
    .w-header-title {
        padding: 0 0 0 70px;
    }
    .w-header-title-location {
        font-size: 20px;
    }
}

@media (max-width: 767px) {
    .w-header {
        padding: 0;
    }
    .w-header .container-fluid {
        border-bottom: solid 5px $color-brand;
    }
    .w-header-logo {
        border-bottom: 0;
    }
    .w-header-title {
        padding: 0 5px;
        margin: 0 auto;
    }
}

@media (max-width: 479px) {
    .w-header-logo {
        -webkit-flex-basis: 105px;
        flex-basis: 105px;
        padding: 20px 10px;
    }
    .w-header-title h1,
    .w-header-title h2 {
        font-size: 16px;
    }
    .w-header-title-location {
        @include w-bullets(10px);
        font-size: 11px;
    }
}