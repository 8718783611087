$color-brand: #eb0a1e;
$color-text: #000;
$color-brand-text: #fff;
$color-white: #fff;
$color-grey-text: #808080;
$color-grey-background: #f2f2f2;
$border-radius-default: 3px;
$line-height-default: 1.5;
$font-size-default: 16px;
$font-size-small: 14px;
$font-family-default: "ToyotaType",
arial,
helvetica,
sans-serif;
$font-family-light: "ToyotaType-Light",
arial,
helvetica,
sans-serif;
$font-family-semibold: "ToyotaType-Semibold",
arial,
helvetica,
sans-serif;
$font-family-bold: "ToyotaType-Bold",
arial,
helvetica,
sans-serif;
$line-height-headings: 1.1;
$font-family-headings: $font-family-semibold;
$padding-gutter: 15px;
$width-container: $padding-gutter * 2+1200px;
$margin-default: $font-size-default * 1.5;